import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout sectionClass="not-found">
        <SEO title="404: Not found" />
        <section className="notfound u-txt--center">
            <div className="notfound__text-block">
                <h1>404</h1>
                <h4>The page you are looking for doesn’t exist.</h4>
                <p className="c-copy">Take me back to <a href="/">xenios40th.com</a></p>
            </div>
        </section>
    </Layout>
)

export default NotFoundPage
